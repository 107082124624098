import React, { useState } from "react";

function App() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    phone: "",
  });

  const [formStatus, setFormStatus] = useState({
    loading: false,
    errorMessage: "",
    successMessage: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const { name, email, subject, message } = formData;
    if (!name || !email || !subject || !message) {
      return "All fields are required.";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address.";
    }
    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus({ loading: true, errorMessage: "", successMessage: "" });

    const error = validateForm();
    if (error) {
      setFormStatus({
        loading: false,
        errorMessage: error,
        successMessage: "",
      });
      return;
    }

    const apiData = {
      MessageTitle: formData.subject,
      Message: formData.message,
      Email: formData.email,
      GuestName: formData.name,
      Phone: "", // Assuming there's no phone field in your form
    };

    try {
      const response = await fetch(
        "https://pn73r4j70j.execute-api.us-east-1.amazonaws.com/v3/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "7pRiZ0FRHA97UD2MobXSWmwrdCfeBvq2sMRjORKg",
          },
          body: JSON.stringify(apiData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send message");
      }

      setFormStatus({
        loading: false,
        errorMessage: "",
        successMessage: "Your message has been sent. Thank you!",
      });
      setFormData({ name: "", email: "", subject: "", message: "" }); // Reset form
    } catch (error) {
      setFormStatus({
        loading: false,
        errorMessage:
          "There was an error sending your message. Please try again later.",
        successMessage: "",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="php-email-form">
      <div className="row">
        <div className="col-md-4 form-group">
          <input
            type="text"
            name="name"
            className="form-control"
            id="name"
            placeholder="* Your Name"
            value={formData.name}
            onChange={handleChange}
            // required
          />
        </div>
        <div className="col-md-4 form-group mt-3 mt-md-0">
          <input
            type="email"
            className="form-control"
            name="email"
            id="email"
            placeholder="* Your Email"
            value={formData.email}
            onChange={handleChange}
            // required
          />
        </div>
        <div className="col-md-4 form-group mt-3 mt-md-0">
          <input
            type="tel"
            className="form-control"
            name="phone"
            id="phone"
            placeholder="Your Phone Number"
            value={formData.phone}
            onChange={handleChange}
            // required
          />
        </div>
      </div>
      <div className="form-group mt-3">
        <input
          type="text"
          className="form-control"
          name="subject"
          id="subject"
          placeholder="* Subject"
          value={formData.subject}
          onChange={handleChange}
          // required
        />
      </div>
      <div className="form-group mt-3">
        <textarea
          className="form-control"
          name="message"
          rows={5}
          placeholder="* Message"
          value={formData.message}
          onChange={handleChange}
          // required
        />
      </div>
      <div className="my-3">
        {formStatus.loading && <div className="loading">Loading</div>}
        {formStatus.errorMessage && (
          <div className="error-message">{formStatus.errorMessage}</div>
        )}
        {formStatus.successMessage && (
          <div className="sent-message">{formStatus.successMessage}</div>
        )}
      </div>
      <div className="text-center">
        <button type="submit">Send Message</button>
      </div>
    </form>
  );
}

export default App;
